var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"GlobalTable"},[(Array.isArray(_vm.seachDataList) && _vm.seachDataList.length > 0)?[[(_vm.accountType == 'merchant')?_c('GlobalTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"merchantTable",attrs:{"columns":_vm.tableColumns1,"data":this.seachDataList,"currentPage":_vm.page.pageNum,"total":_vm.page.total},on:{"handleCurrentChange":_vm.handleCurrentChange}},[_c('el-table-column',{attrs:{"slot":"uniqNo","label":"账单流水号","align":"center"},slot:"uniqNo",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.serType == '18')?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(row.uniqNo || "--"))]):_c('span',[_vm._v(_vm._s(row.uniqNo || "--"))])]}}],null,false,393843686)}),_c('el-table-column',{attrs:{"slot":"orderNo","label":"原始订单号","align":"center"},slot:"orderNo",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.orderChannel == 'recycle')?_c('div',{staticStyle:{"color":"#0981ff","cursor":"pointer"},on:{"click":function($event){return _vm.toOrderDetails(row)}}},[_c('span',{staticStyle:{"border-bottom":"1px solid #0981ff"}},[_vm._v(_vm._s(row.orderNo))])]):_c('span',[_vm._v(_vm._s(row.orderNo || "--"))])]}}],null,false,3780316502)}),_c('el-table-column',{attrs:{"slot":"opt","label":"账单类型","align":"center"},slot:"opt",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.opt == '01' ? '入账' : row.opt == '02' ? '出账' : '挂账'))])]}}],null,false,1246307576)}),_c('el-table-column',{attrs:{"slot":"companyName","label":"交易门店商家","align":"center"},slot:"companyName",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.companyName || "--"))])]}}],null,false,946211620)}),_c('el-table-column',{attrs:{"slot":"storeName","label":"交易门店","align":"center"},slot:"storeName",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.storeName || "--"))])]}}],null,false,3641987484)}),_c('el-table-column',{attrs:{"slot":"amount","label":"金额","align":"center"},slot:"amount",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.opt != '03')?_c('span',{style:(row.opt == '01' ? 'color:green' : 'color:red')},[_vm._v(_vm._s(row.amount >= 0 && row.opt == '01' ? ("+" + (row.amount)) : ("-" + (row.amount))))]):_c('span',{style:(row.serType == '15' || row.serType == '23' ? 'color:green' : 'color:red')},[_vm._v(_vm._s(row.amount && (row.serType == '15' || row.serType == '23') ? ("+" + (row.amount)) : ("-" + (row.amount))))])]}}],null,false,2655624888)}),_c('el-table-column',{attrs:{"slot":"nowBalance","label":"当前余额","align":"center"},slot:"nowBalance",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.nowBalance || "--"))])]}}],null,false,3256351060)}),_c('el-table-column',{attrs:{"slot":"remark","label":"账单备注","width":"130rpx","align":"center"},slot:"remark",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.remark,"placement":"top"}},[_c('div',{staticClass:"Remarks"},[_vm._v(" "+_vm._s(row.remark)+" ")])])]}}],null,false,655883071)})],1):(_vm.accountType == 'company')?_c('GlobalTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"companyTable",attrs:{"columns":_vm.tableColumns2,"data":this.seachDataList,"currentPage":_vm.page.pageNum,"total":_vm.page.total},on:{"handleCurrentChange":_vm.handleCurrentChange}},[_c('el-table-column',{attrs:{"slot":"uniqNo","label":"账单流水号","align":"center"},slot:"uniqNo",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.serType == '62')?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(row.uniqNo || "--"))]):_c('span',[_vm._v(_vm._s(row.uniqNo || "--"))])]}}],null,false,1277075403)}),_c('el-table-column',{attrs:{"slot":"orderNo","label":"原始订单号","align":"center"},slot:"orderNo",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.orderChannel == 'recycle')?_c('div',{staticStyle:{"color":"#0981ff","cursor":"pointer"},on:{"click":function($event){return _vm.toOrderDetails(row)}}},[_c('span',{staticStyle:{"border-bottom":"1px solid #0981ff"}},[_vm._v(_vm._s(row.orderNo))])]):_c('span',[_vm._v(_vm._s(row.orderNo || "--"))])]}}],null,false,3780316502)}),_c('el-table-column',{attrs:{"slot":"opt","label":"账单类型","align":"center"},slot:"opt",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.opt == '01' ? '入账' : row.opt == '02' ? '出账' : '挂账'))])]}}],null,false,1246307576)}),_c('el-table-column',{attrs:{"slot":"merchantName","label":"交易回收商家","align":"center"},slot:"merchantName",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.merchantName || "--"))]),(row.merchantNice)?_c('div',[_c('el-tag',{attrs:{"type":"warning","effect":"dark","size":"mini"}},[_vm._v("靓机回收商")])],1):_vm._e()]}}],null,false,1476825689)}),_c('el-table-column',{attrs:{"slot":"storeName","label":"交易门店","align":"center"},slot:"storeName",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.storeName || "--"))])]}}],null,false,3641987484)}),_c('el-table-column',{attrs:{"slot":"amount","label":"金额","align":"center"},slot:"amount",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.opt != '03')?_c('span',{style:(row.opt == '01' ? 'color:green' : 'color:red')},[_vm._v(_vm._s(row.amount >= 0 && row.opt == '01' ? ("+" + (row.amount)) : ("-" + (row.amount))))]):_c('span',{style:(row.serType == '15' || row.serType == '23' ? 'color:green' : 'color:red')},[_vm._v(_vm._s(row.amount && (row.serType == '15' || row.serType == '23') ? ("+" + (row.amount)) : ("-" + (row.amount))))])]}}],null,false,2655624888)}),_c('el-table-column',{attrs:{"slot":"nowBalance","label":"当前余额","align":"center"},slot:"nowBalance",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.nowBalance || "--"))])]}}],null,false,3256351060)}),_c('el-table-column',{attrs:{"slot":"remark","label":"账单备注","width":"130rpx","align":"center"},slot:"remark",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.remark,"placement":"top"}},[_c('div',{staticClass:"Remarks"},[_vm._v(" "+_vm._s(row.remark)+" ")])])]}}],null,false,655883071)})],1):(_vm.accountType == 'companyDiffFund')?_c('GlobalTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"companyDiffFundTable",attrs:{"columns":_vm.tableColumns3,"data":this.seachDataList,"currentPage":_vm.page.pageNum,"total":_vm.page.total},on:{"handleCurrentChange":_vm.handleCurrentChange}},[_c('el-table-column',{attrs:{"slot":"uniqNo","label":"账单流水号","align":"center"},slot:"uniqNo",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.serType == '18')?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(row.uniqNo || "--"))]):_c('span',[_vm._v(_vm._s(row.uniqNo || "--"))])]}}],null,false,393843686)}),_c('el-table-column',{attrs:{"slot":"orderNo","label":"关联回收单号","align":"center"},slot:"orderNo",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.orderChannel == 'recycle')?_c('div',{staticStyle:{"color":"#0981ff","cursor":"pointer"},on:{"click":function($event){return _vm.toOrderDetails(row)}}},[_c('span',{staticStyle:{"border-bottom":"1px solid #0981ff"}},[_vm._v(_vm._s(row.orderNo))])]):_c('span',[_vm._v(_vm._s(row.orderNo || "--"))])]}}],null,false,3780316502)}),_c('el-table-column',{attrs:{"slot":"companyName","label":"门店商家名称","align":"center"},slot:"companyName",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.companyName || "--"))])]}}],null,false,946211620)}),_c('el-table-column',{attrs:{"slot":"storeName","label":"门店","align":"center"},slot:"storeName",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.storeName || "--"))])]}}],null,false,3641987484)}),_c('el-table-column',{attrs:{"slot":"opt","label":"账单类型","align":"center"},slot:"opt",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.opt == '01' ? '入账' : row.opt == '02' ? '出账' : '挂账'))])]}}],null,false,1246307576)}),_c('el-table-column',{attrs:{"slot":"merchantName","label":"交易商家","align":"center"},slot:"merchantName",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.merchantName || "--"))]),(row.merchantNice)?_c('div',[_c('el-tag',{attrs:{"type":"warning","effect":"dark","size":"mini"}},[_vm._v("靓机回收商")])],1):_vm._e()]}}],null,false,1476825689)}),_c('el-table-column',{attrs:{"slot":"amount","label":"金额","align":"center"},slot:"amount",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.opt != '03')?_c('span',{style:(row.opt == '01' ? 'color:green' : 'color:red')},[_vm._v(_vm._s(row.amount >= 0 && row.opt == '01' ? ("+" + (row.amount)) : ("-" + (row.amount))))]):_c('span',{style:(row.serType == '15' || row.serType == '23' ? 'color:green' : 'color:red')},[_vm._v(_vm._s(row.amount && (row.serType == '15' || row.serType == '23') ? ("+" + (row.amount)) : ("-" + (row.amount))))])]}}],null,false,2655624888)}),_c('el-table-column',{attrs:{"slot":"nowBalance","label":"当前差异基金余额","align":"center"},slot:"nowBalance",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.nowBalance || "--"))])]}}],null,false,3256351060)}),_c('el-table-column',{attrs:{"slot":"remark","label":"备注","width":"130rpx","align":"center"},slot:"remark",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.remark,"placement":"top"}},[_c('div',{staticClass:"Remarks"},[_vm._v(" "+_vm._s(row.remark)+" ")])])]}}],null,false,655883071)})],1):_vm._e()]]:[(_vm.seachDataList.length <= 0)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.searchTabLoading),expression:"searchTabLoading"}],staticStyle:{"width":"100%","text-align":"center","padding":"80px 0px","font-size":"24px","color":"rgb(102, 102, 102)","background":"white","font-weight":"bold"}},[_vm._v(" 暂无数据~ ")]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }