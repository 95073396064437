import {validateMobile,validateNumber,validateNumberEight,validateMobileLandline} from './validate'

/* 手机号*/
export function formValidateMobile(rule, value, callback) {
    if (!value || validateMobile(value)) {
        callback()
    } else {
        callback(new Error('请输入正确的手机号码'))
    }
}

/* 手机号座机号*/
export function formValidateMobileLandline(rule, value, callback) {
    if (!value || validateMobileLandline(value)) {
        callback()
    } else {
        callback(new Error('请输入正确的电话号码'))
    }
}

// 数字年限
export function formValidateNumber(rule, value, callback) {
    if (!value || validateNumber(value)) {
        callback()
    } else {
        callback(new Error('请输入正确年限（50以内的正整数）'))
    }
}
// 数字年限
export function formValidateNumberEight(rule, value, callback) {
    if (!value || validateNumberEight(value)) {
        callback()
    } else {
        callback(new Error('输入大于0的正整数且在8位数内'))
    }
}