<template>
  <div class="class-list">
    <GlobalInfoBar
      title="支付账单"
      content="统计汇总线上支付交易账单记录（点击原始订单号可跳转至订单详情）"
    />
<!--    <div class="button_top">-->
<!--      <div class="radiobtn">-->
<!--        <div class="faultBtn">-->
<!--            <div v-bind:class="this.clickShow=== '01'? 'active' : 'none'" @click="BtnClick('active')">-->
<!--              回收商账单({{numHandle(billNumberObj.merchantUniqCount || 0)}})-->
<!--            </div>-->
<!--            <div v-bind:class="this.clickShow=== '02'? 'active' : 'none'" @click="BtnClick('before')">-->
<!--              门店商账单({{numHandle(billNumberObj.companyUniqCount || 0)}})-->
<!--            </div>-->
<!--            &lt;!&ndash; 新增 &ndash;&gt;-->
<!--            <div v-bind:class="this.clickShow=== '03'? 'active' : 'none'" @click="BtnClick('03')">-->
<!--              门店差异基金账单({{numHandle(billNumberObj.companyDiffFundUniqCount || 0)}})-->
<!--            </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!-- <Info/> -->
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <From :accountType="accountType"/>
      </div>
      <Table :accountType="accountType" @billNumber="billNumber"/>
    </GlobalChunk>
  </div>
</template>

<script>
import From from "./Form";
import Table from "./Table";
import GlobalChunk from "@/components/global/components/GlobalChunk.vue";
import GlobalInfoBar from "@/components/global/components/GlobalInfoBar.vue";
export default {
  name: "class-list",
  components: {
    GlobalInfoBar,
    GlobalChunk,
    From,
    Table
  },
  data() {
    return {
      clickShow: '01',
      accountType: "merchant",
      billNumberObj: {},
    };
  },
  created() {
    if(this.$route.query.orderNo){
      this.clickShow = '02'
      this.accountType = 'company'
    }
    console.log(this.$route.path);
    this.$store.commit('tagsView/ROUTER_PATH', this.$route.path )
  },
  methods: {
    BtnClick(type){
      this.$route.path.replace()
      if(type==='active'){
        this.clickShow='01'
        this.accountType = 'merchant'
      }else if(type==='before'){
        this.clickShow='02'
        this.accountType = 'company'
      }else if(type === '03'){
        this.clickShow='03'
        this.accountType = 'companyDiffFund'
      }
    },
    billNumber(obj){
      this.billNumberObj=obj
    },
    // 999以下的正常显示
    // 1000-9999显示1k，1.1k，9.9k
    // 10000以上的显示1w，9.9w，99w，999w
    numHandle(num){
      if(num<=9999){
        return num
      }else{
        if((num/10000).toString().indexOf('.') < 0){
          return (num/10000) + 'w'
        }else{
          return this.formatDecimal(num/10000,1) + 'w'

        }
      }
    },
    formatDecimal(num, decimal) {
      num = num.toString()
      let index = num.indexOf('.')
      if (index !== -1) {
          num = num.substring(0, decimal + index + 1)
      } else {
          num = num.substring(0)
      }
      num = parseFloat(num).toFixed(decimal).toString()
      if(num[num.indexOf('.')+1] == 0){
        num = num.substring(0, num.indexOf('.'))
      }
      return num
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.class-list{
  .button_top{
    cursor: pointer;
    margin-bottom: 20px;;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .faultBtn{
    width:550px;
    height: 32px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    .active{
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      background: #0981FF;
      border-radius: 20px;
    }
    .none{
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      border-radius: 20px;
    }
  }
}
</style>
